<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.Payment.Yandex.Success.Title") }}
        </h1>
        <img src="@/assets/images/success.svg">
    </div>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: this.$t("Interface.Payment.Yandex.Success.Title")
        }
    }
}
</script>
